const getSites = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/sites/portfolio')
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getNonRoutineEventsForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/nonroutineevent/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getNonRoutineEventById = async (nonRoutineEventId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/nonroutineevent/edit/${nonRoutineEventId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getNonRoutineEventTypes = async () => {
    let result = null
    try {
        result = await window.axios.get(`/v1/nonroutineeventtype/index`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const createNonRoutineEvent = async (nonRoutineEvent) => {
    try {
        await window.axios.post('/v1/nonroutineevent/create', nonRoutineEvent)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const updateNonRoutineEvent = async (nonRoutineEvent) => {
    try {
        await window.axios.put('/v1/nonroutineevent/update', nonRoutineEvent)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const deleteNonRoutineEvent = async (nonRoutineEventId) => {
    let result = null
    console.log(nonRoutineEventId);

    try {
        result = await window.axios.delete(`/v1/nonroutineevent/delete/${nonRoutineEventId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

//Energy Adjustment API
export const downloadNewEnergyAdjustmentSpreadsheet = async (nonRoutineEventId) => {
    let result = null;
    var data = {
        NonRoutineEventId: nonRoutineEventId
    }
    try {
        result = await window.axios.post('/v1/NonRoutineEvent/EnergyAdjustment/downloadnewsheet', data, { responseType: 'blob'})
        var spreadSheet = result.data;
        return spreadSheet;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
}

export const downloadExistingEnergyAdjustmentSpreadsheet = async (nonRoutineEventId, versionId) => {
    let result = null;
    var data = {
        VersionId: versionId
    }
    try {
      result = await window.axios.post('/v1/NonRoutineEvent/EnergyAdjustment/downloadsheet/'+nonRoutineEventId, data, { responseType: 'blob'})
      var spreadSheet = result.data;
      return spreadSheet;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
}

export const uploadEnergyAdjustmentSpreadsheet = async (nonRoutineEventId, file) => {
    let result = null;
    let formData = new FormData;
    formData.append("uploadedFile", file, file.name)
    let config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    try {
      result = await window.axios.post('/v1/NonRoutineEvent/EnergyAdjustment/uploadsheet/'+nonRoutineEventId, formData, config)
      var status = result.data;
      return status;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }

  export const getChartData = async (nonRoutineEventId, version) => {
    let result = null;
    let data = {
      Version: version
    }
    try {
      result = await window.axios.post('/v1/NonRoutineEvent/EnergyAdjustment/chartdata/'+nonRoutineEventId, data)
      var status = result.data;
      return status;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
  }

export default {
    getSites,
    getNonRoutineEventTypes,
    getNonRoutineEventsForSite,
    getNonRoutineEventById,
    createNonRoutineEvent,
    updateNonRoutineEvent,
    deleteNonRoutineEvent,
    downloadNewEnergyAdjustmentSpreadsheet,
    downloadExistingEnergyAdjustmentSpreadsheet,
    uploadEnergyAdjustmentSpreadsheet,
    getChartData
}